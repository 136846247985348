import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import styled from "styled-components";
import MainLayout from "./Layout/MainLayout";

import EndObserver from "../Components/mainComp/EndObserver";
import { gql, useQuery } from "@apollo/client";
import EpisodeCartList from "../Components/mainComp/EpisodeCartList";

import { useParams } from "react-router";
import { Loading } from "../Components/Loading";
// import ReactHtmlParser from 'react-html-parser';
import parse from 'html-react-parser';
import NotMatch from "./NotMatch";
import { Link } from "react-router-dom";
import SliderNew from "../Components/Helper/SliderNew";
import Play from '../Assets/svgPlayer/play.svg'
import Pause from '../Assets/svgPlayer/pause.svg'
import Forward from '../Assets/svgPlayer/forward-15-seconds.svg';
import Backward from '../Assets/svgPlayer/backward-5-seconds.svg';
import { ChangeTitlePage, generateRichSchema, MakeChangeCanonical } from "../Components/Helper/PageTitles";
import { IconDanger } from "../Assets/IconDanger";

import banner from "../Assets/banner/dailybanner.webp"
import bannerMob from "../Assets/banner/dailybanner-mob.webp"

const Style = styled.div`
  /* min-height: 100vh; */
  display: grid;
  grid-template-columns:
    6.09% repeat(12, calc(calc(84.7% / 12) - calc(17.16% / 12)))
    6.09%;
  grid-column-gap: 1.56%;
  .loading {
    margin: 1rem 0;
    grid-column: 2/14;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .top-part {
    z-index: 0;
    grid-column: 1/15;
    background-color: #B71F38;
    height: 24vw;
    border: 3px solid #1E1E1E;
    position: relative;
    overflow: hidden;
    isolation: isolate;
    &::after{
      z-index: 2;
      content: "";
      position: absolute;
      inset: 0;
      background-color: #00000040;
    }
    img {
      position: absolute;
      inset: -10%;
      width: 120%;
      height: 120%;
      object-fit: cover;
      filter: blur(30px);
      opacity: 0;
      transition: all 0.2s ease-in-out;
    }
  }
  .container-1000 {
    margin: 2rem 0 0;
    grid-column: 2/14;
    border: 1px solid #000000;
    box-shadow: 6px 6px 0px 0px #000000;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
  }
  .episode-title {
    z-index: 2;
    border: 1px solid #000000;
    box-shadow: 6px 6px 0px 0px #000000;
    border-radius: 4px;
    grid-column: 2/14;
    display: flex;
    flex-direction: column;
    margin-top: -70px;
    background-color: #fff;
    .line {
      height: 2px;
      background-color: #000000;
    }
    .row {
      display: flex;
      gap: 2rem;
      padding: 1rem 2rem;
      align-items: center;
      .image {
        width: 140px;
        aspect-ratio: 1;
        border-radius: 4px;
        overflow: hidden;
        margin-top: calc(-2rem - 40px);
        border: 1px solid #000000;
        background-color: #fff;
        img {
          width: 100%;
          height: 100%;
          display: block;
        }
      }
      .data {
        display: flex;
        flex-direction: column;
      }
      
      .title {
        font-size: 1.25rem;
        font-weight: 700;
      }
      .autor {
        font-size: 0.87rem;
        font-weight: 700;
        color: #979797;
      }
      
      .expand {
        flex-grow: 1;
      }
      .actions {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .action {
          cursor: pointer;
          text-decoration: none;
          background: #000000;
          color: #fff;
          border-radius: 4px;
          padding: 0.5rem 1rem;
          font-weight: 500;
          display: flex;
          align-items: center;
          gap: 0.5rem;
        }
      }
    }
  }
  .banner {
    border: 1px solid #000000;
    box-shadow: 6px 6px 0px 0px #000000;
    border-radius: 4px;
    grid-column: 2/14;
    display: flex;
    margin-top: 1.5rem;
    flex-direction: column;
    img {
      width: 100%;
      display: block;
    }
  }
  .episode-warning {
    border: 1px solid #000000;
    box-shadow: 6px 6px 0px 0px #000000;
    border-radius: 4px;
    grid-column: 2/14;
    display: flex;
    margin-top: 1.5rem;
    background-color: #fff;
    background-color: #ED303D15;
    flex-direction: column;
    .row {
      display: flex;
      gap: 1rem;
      padding: 1rem 2rem;
      align-items: center;
      .icon {
        svg {
          display: block;
          width: 32px;
          height: 32px;
          stroke: #ED303D;
        }
      }
      .data {
        display: flex;
        flex-direction: column;
      }
    }
  }
  .episode-cart {
    margin: 2rem 0;
    grid-column: 2/14;
    border: 1px solid #000000;
    box-shadow: 6px 6px 0px 0px #000000;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    background-color: #fff;
    .data {
      width: calc(50% - 4rem);
      flex-grow: 1;
      padding: 1rem 2rem;
      .desc-title {
        font-size: 1rem;
        font-weight: 700;
      }
      .desc {
        font-size: 0.87rem;
        font-weight: 500;
      }
    }
    .line {
      width: 1px;
      background-color: #000000;
    }
    .player {
      width: calc(50% - 2rem);
      flex-grow: 1;
      background: rgba(234, 92, 0, 0.1);
      padding: 2rem 1rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;
      .audio-container {
        display: none;
      }
      .timeline {
        margin: 0 3rem;
        align-self: normal;
      }
      .image {
        width: 70%;
        aspect-ratio: 1;
        border-radius: 4px;
        overflow: hidden;
        border: 1px solid #000000;
        background-color: #fff;
        img {
          width: 100%;
          height: 100%;
          display: block;
          opacity: 0;
          transition: all 0.2s ease-in-out;
        }
      }
      .title {
        margin-top: 1rem;
        font-size: 1.25rem;
        font-weight: 700;
        text-align: center;
      }
      .podcast {
        font-size: 0.87rem;
        font-weight: 700;
        color: #979797;
        text-decoration: none;
      }
      .actions {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        margin-bottom: 2rem;
        img {
          display: block;
          width: 32px;
          height: 32px;
        }
        .icons {
          &.play {
            padding: 0.5rem;
            border-radius: 100px;
            background: #12121264;
          }
        }
        
      }
    }
  }
  .episodes {
    margin: 2rem 0;
    grid-column: 2/14;
    border: 1px solid #000000;
    box-shadow: 6px 6px 0px 0px #000000;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    .title-list {
      padding: 0.5rem 2rem;
      font-size: 1.25rem;
      font-weight: 700;
      border-bottom: 1px solid #000;
    }
  }

  @media screen and (max-width: 850px) {
    .top-part {
      height: 80vw;
    }
    .container-1000 {
      grid-column: 1/15;
    }
    .episode-title {
      grid-column: 1/15;
      .row {
        flex-direction: column;
        gap: 0.75rem;
        padding: 1rem;
        .expand {
          display: none;
        }
        .data {
          align-self: normal;
          align-items: center;
          gap: 0.25rem;
        }
      }
    }
    .banner {
      grid-column: 1/15;
    }
    .episode-warning {
      grid-column: 1/15;
      .row {
        flex-direction: column;
        gap: 0.75rem;
        padding: 1rem;
        .data {
          align-self: normal;
          align-items: center;
          gap: 0.25rem;
        }
      }
    }
    .episode-cart {
      grid-column: 1/15;
      flex-direction: column;
      .data {
        overflow: hidden;
        width: calc(100% - 2rem);
        padding: 1rem 1rem;
        white-space: pre-wrap;
        a {
          white-space: pre-wrap;
        }
      }
      .line {
        width: auto;
        height: 1px;
        background-color: #000000;
      }
      .player {
        width: calc(100% - 2rem);
        flex-grow: 1;
        background: rgba(234, 92, 0, 0.1);
      }
    }
    .episodes {
      grid-column: 1/15;
      .title-list {
        padding: 0.5rem 1rem;
      }
    }
  }
  
`;

const Episode_query = gql`
  query EpisodePage($Ep_ID: ID!) {
    episode(Ep_ID: $Ep_ID){
      Ep_ID
      Title
      Description
      pubDate
      duration
      audio
      imagecache{
        size500
      }
    }
  }
`
const Podcasts_query = gql`
  query PodcastPage($En_Name: String!) {
    podcastEn(En_Name: $En_Name){
      Pod_ID
      User_ID
      Cat_Name
      Pr_Name
      En_Name
      title
      author
      imageURL
      visits
      follower
      follow
      description
      XML_LINK
      imagecache {
        size500
      }
      episodes{
        Ep_ID
        Pod_ID
        Title
        pubDate
        image
      }
    }
  }
`;

export default function EpisodePage(props) {
  const params = useParams()
  const audioRef = useRef();
  const [updator, setUpdator] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [duration, setDuration] = useState(false);
  const [currentTime, setCurrentTime] = useState(false);
  const [loadedEp, setLoadedEp] = useState(10);
  const [loadingAudio, setLoadingAudio] = useState(false);
  const { data: dataPod, loading: loadingPod, error: errorPod } = useQuery(Podcasts_query,{
      variables: {
        En_Name: params.EnName,
      }
  });
  const { data, loading, error } = useQuery(Episode_query,{
    variables: {
      Ep_ID: params.Ep_ID,
    }
});
  useEffect(() => {
    MakeChangeCanonical("https://podkadeh.com/podcast/" + params.EnName + "/" + params.Ep_ID)
  })
  useEffect(() => {
    MakeChangeCanonical("https://podkadeh.com/podcast/" + params.EnName + "/" + params.Ep_ID)
  }, [params])
  useEffect(() => {
    
    if(data && dataPod && dataPod.podcastEn && data.episode){
      ChangeTitlePage(
        "پادکده؛ " + dataPod.podcastEn.title + "؛ " + data.episode.Title, 
        data.episode.Description,
        "https://podkadeh.com/podcast/" + params.EnName + "/" + params.Ep_ID
      );
      let lastLink = "https://podkadeh.com/podcast/" + params.EnName + "/" + params.Ep_ID

      let time = new Date(data.episode.pubDate)
      let article = {
        "@id": "https://podkadeh.com/podcast/" + params.EnName + "/" + data.episode.Ep_ID + "/#article",
        "@type": "Article",
        "headline": data.episode.Title,
        "url": "https://podkadeh.com/podcast/" + params.EnName + "/" + data.episode.Ep_ID,
        "image": data.episode.imagecache.size500,
        "datePublished": time.toISOString(),
        "author": { 
          "@id": "https://podkadeh.com/podcast/" + params.EnName + "/#author" ,
          "url": "https://podkadeh.com/podcast/" + dataPod.podcastEn.title, 
          "name": "https://podkadeh.com/podcast/" + params.EnName, 
        }
      }

      // let articles = [];
      // if(data.podcastEn.episodes.length){
      //   for (let i = 0; i < data.podcastEn.episodes.length; i++) {
      //     const episode = data.podcastEn.episodes[i];
      //     let time = new Date(episode.pubDate)
      //     articles.push({
      //       "@id": "https://podkadeh.com/podcast/" + params.EnName + "/" + episode.Ep_ID + "/#article",
      //       "@type": "Article",
      //       "headline": episode.Title,
      //       "url": "https://podkadeh.com/podcast/" + params.EnName + "/" + episode.Ep_ID,
      //       "image": episode.image,
      //       "datePublished": time.toISOString(),
      //       "author": { "@id": "https://podkadeh.com/podcast/" + params.EnName + "/#author" }
      //     });
      //   }
      // }
      // let profile = {
      //   "@type": "ProfilePage",
      //   "mainEntity": {
      //     "@id": "https://podkadeh.com/podcast/" + params.EnName + "/#author",
      //     "@type": "Organization",
      //     "name": data.podcastEn.title,
      //     "alternateName": params.EnName,
      //     "identifier": params.EnName,
      //     "description": data.podcastEn.description,
      //     "image": data.podcastEn.imagecache.size500,
      //     "url": "https://podkadeh.com/podcast/" + params.EnName + "/",
      //   },
      //   "url": "https://podkadeh.com/podcast/" + params.EnName + "/",
      //   "hasPart": articles
      // }

      let breadData = [
        {"@type":"ListItem", "position":1, "name": "podkadeh", "item": "https://podkadeh.com/"},
        {"@type":"ListItem", "position":2, "name": dataPod.podcastEn.title , "item": "https://podkadeh.com/podcast/" + params.EnName},
        {"@type":"ListItem", "position":3, "name": data.episode.Title, "item": lastLink}
      ];
      
      generateRichSchema(false, article, {
        "@type":"BreadcrumbList",
        "@id":lastLink + "/#breadcrumb",
        "itemListElement": breadData
      })

    }
  }, [data, dataPod])
  useEffect(() => {
    if(data && dataPod){
        setLoadingAudio(true)
        pauseFunc();
        let audio = audioRef.current;
        audio.src = data.episode.audio;
        audio.load();
        audio.addEventListener('canplaythrough', ()=>{ 
          setLoadingAudio(false)
          setDuration(audio.duration)
          setCurrentTime(audio.currentTime)
        },false);
        makeMediaSession();
    }
  }, [data, dataPod])
  useEffect(() => {
    // console.log("updated", playing)
    if(playing){
      playFunc()
    } else {
      pauseFunc()
    }
  }, [playing])
  const makeMediaSession = () => {
    if ('mediaSession' in navigator) {
        var artwork = new Image;
        artwork.src = data.episode.imagecache.size500;
        artwork.onload = function (){
            let size = this.width + 'x' + this.height;
            let type = data.episode.imagecache.size500.split('.').pop();
            navigator.mediaSession.metadata = new window.MediaMetadata({ 
                title: data.episode.Title,
                artist: dataPod.podcastEn.author,
                album: dataPod.podcastEn.title,
                artwork:[
                  { 
                    src: data.episode.imagecache.size500,   
                    sizes: size,   
                    type: 'image/' + type
                  }
                ]
            });
        }
        
        
        navigator.mediaSession.setActionHandler('play', function() { playFunc(); });
        navigator.mediaSession.setActionHandler('pause', function() { pauseFunc() });
        navigator.mediaSession.setActionHandler('seekbackward', function() { seekBackward(10) });
        navigator.mediaSession.setActionHandler('seekforward', function() { seekForward(10) });
    }
  }
  
  const makeTimeNum = (time) => {
    if(time < 10){
        return '0' + time;
    } else {
        return time
    }
  }
  const makeTimes = (time) => {
    if(!isNaN(time)){
        let hour = Math.floor(time/3600)
        let minute = (Math.floor( time / 60 ) % 60 );
        let sec = (Math.floor( time ) % 60 );

        return makeTimeNum(hour) + ':' + makeTimeNum(minute) + ':' + makeTimeNum(sec);
    } else {
        return 0
    }
  }
  const playFunc = () => {
    // console.log('paly func');
    setPlaying(true);
    let status = playAudio();
  }
  const pauseFunc = () => {
      // console.log('pausing func')
      setPlaying(false);
      pauseAudio();
  }
  const playAudio = () => {
    let audio = audioRef.current;
    if(audio && data) {
        setUpdator(setInterval(updateData,500))
        return audio.play();
    }
  }
  const pauseAudio = () => {
    let audio = audioRef.current;
    if(audio && data) {
        audio.pause();
        clearInterval(updator);
    }
  }
  const seekForward = (sec) => {
    if(audioRef.current) {
        let audio = audioRef.current;
        seekTo(audio.currentTime + sec);
    }
  }
  const seekBackward = (sec) => {
    if(audioRef.current) {
        let audio = audioRef.current;
        seekTo(audio.currentTime - sec);
    }
  }
  const seekTo = (sec) => {
    if(audioRef.current) {
        let audio = audioRef.current;
        if(sec < audio.duration && sec > 0){
            audio.currentTime = sec;
            // console.log("seekTo",playing);
            updateData();
        }
    }
  }
  const updateData = () => {
    if(playing && audioRef.current) {
        let audio = audioRef.current;
        // console.log("updateData",playing);
        updatePlayerData(playing, audio.duration , audio.currentTime);
    }
  }
  const updatePlayerData = (newplaying = playing, newduration, newcurrentTime) => {
    // console.log("updatePlayerData",newplaying);
    setPlaying(newplaying)
    setDuration(newduration)
    setCurrentTime(newcurrentTime)
  }
  const makePercentToTime = (perc, time) => {
    let newTime = parseInt((perc/1000) * time);
    return newTime;
  }
  const timeChange =(e) => {
    pauseAudio();
    let val = e.target.value;
    let timeElapsed = makePercentToTime(val, duration);
    seekTo(timeElapsed);
    if(playing){
      playAudio();
    }
  }
  
  const getEpisodes = () => {
    let rows = [];
    let length = 0;
    if(loadingPod){
      return <Loading/>
    }
    if(dataPod){
      length = dataPod.podcastEn.episodes.length;
    }
    if(length > loadedEp){
        length = loadedEp
    }
    for (let i = 0; i < length; i++) {
        const value = dataPod.podcastEn.episodes[i];
        rows.push(
            <EpisodeCartList 
              key={i} 
              active={(params.Ep_ID === value.Ep_ID) ? 1 : 0}
              Pod_ID={value.Pod_ID} 
              Ep_ID={value.Ep_ID} 
              image={value.image} 
              title={value.Title} 
              podcast={value.pubDate} 
              openCart={props.openCart}
              baseLink={"/podcast/" + dataPod.podcastEn.En_Name + "/" + value.Ep_ID}
            />
        )
    }
    return rows;
  }
  const loadMore = () => {
    if(loadingPod){
      return "";
    }
    if((dataPod.podcastEn.episodes.length - loadedEp) > 10){
        setLoadedEp(loadedEp + 10);
    } else {
        setLoadedEp(dataPod.podcastEn.episodes.length);
    }
  }
  const shareHandel = () => {
    let link = encodeURI("https://podkadeh.com/podcast/" + params.EnName + "/" + params.Ep_ID)
    if(typeof window.navigator.share === "function" ){
      window.navigator.share({
        title: "Share " + data.episode.Title,
        text: "link: ",
        url: link,
      });
      return;
    }
    navigator.clipboard.writeText(link).then(() => {
      props.updateError({
        text: 'لینک کپی شد',
        time: 3,
        type: 'confirm'
      });
    })
  };
  if (loading) return (
    <MainLayout {...props}>
      <Style>
        <div className="loading">
            <Loading/>
        </div>
      </Style>
    </MainLayout>
  );
  if (error || (dataPod && dataPod.podcastEn && dataPod.podcastEn.Pod_ID === "")) {
    return <NotMatch/>;
  };
  
  var p = {}
  var ep = {};
  if(data){
    ep = data.episode;
  }
  if(dataPod){
    p = dataPod.podcastEn;
  }

  let time = new Date(ep.pubDate)
  let timeString = time.getHours() + ':' + time.getMinutes() + ' ' +time.getFullYear() + '/' + (time.getMonth()+1) + '/' + time.getDate() ;
  let showWarning = false
  if(p.XML_LINK){
    if(p.XML_LINK.search("https://anchor.fm/") !== -1){
      showWarning = true
    }
  }


  return (
    <MainLayout {...props}>
      <Style>
        <div className="top-part">
          <img src={(ep.imagecache) ? ep.imagecache.size500 : ""} alt={ep.Title} onLoad={(e) => e.target.style.opacity = 1}/>
        </div>
        <div className="episode-title">
          <div className="row">
            <div className="image">
              <img src={(ep.imagecache) ? ep.imagecache.size500 : ""} alt={ep.Title} onLoad={(e) => e.target.style.opacity = 1}/>
            </div>
            <div className="data">
              <h1 className="title">
                {ep.Title}
              </h1>
              <div className="autor">
                انتشار: {timeString}
              </div>
            </div>
            <div className="expand"></div>
            <div className="actions">
                <div className="action" onClick={shareHandel}>
                  <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.4" d="M27.1471 17.4733C26.6538 17.4733 26.2404 17.1 26.1871 16.6066C25.8671 13.6733 24.2938 11.0333 21.8671 9.35331C21.4271 9.04664 21.3204 8.44664 21.6271 8.00664C21.9338 7.56664 22.5338 7.45997 22.9738 7.76664C25.8671 9.77997 27.7338 12.9266 28.1204 16.4066C28.1738 16.94 27.7871 17.42 27.2538 17.4733C27.2138 17.4733 27.1871 17.4733 27.1471 17.4733Z" fill="white"/>
                    <path opacity="0.4" d="M4.9864 17.54C4.95973 17.54 4.91973 17.54 4.89307 17.54C4.35973 17.4867 3.97307 17.0067 4.0264 16.4734C4.3864 12.9934 6.2264 9.84669 9.09307 7.82002C9.51973 7.51336 10.1331 7.62002 10.4397 8.04669C10.7464 8.48669 10.6397 9.08669 10.2131 9.39336C7.81307 11.1 6.25307 13.74 5.95973 16.66C5.9064 17.1667 5.47973 17.54 4.9864 17.54Z" fill="white"/>
                    <path opacity="0.4" d="M21.3201 28.6334C19.6801 29.42 17.9201 29.82 16.0801 29.82C14.1601 29.82 12.3335 29.3934 10.6268 28.5267C10.1468 28.3 9.96013 27.7134 10.2001 27.2334C10.4268 26.7534 11.0135 26.5667 11.4935 26.7934C12.3335 27.22 13.2268 27.5134 14.1335 27.6867C15.3601 27.9267 16.6135 27.94 17.8401 27.7267C18.7468 27.5667 19.6401 27.2867 20.4668 26.8867C20.9601 26.66 21.5468 26.8467 21.7601 27.34C22.0001 27.82 21.8135 28.4067 21.3201 28.6334Z" fill="white"/>
                    <path d="M16.0666 3.18005C14 3.18005 12.3066 4.86005 12.3066 6.94005C12.3066 9.02005 13.9866 10.7001 16.0666 10.7001C18.1466 10.7001 19.8266 9.02005 19.8266 6.94005C19.8266 4.86005 18.1466 3.18005 16.0666 3.18005Z" fill="white"/>
                    <path d="M6.73363 18.9933C4.66697 18.9933 2.97363 20.6733 2.97363 22.7533C2.97363 24.8333 4.65363 26.5133 6.73363 26.5133C8.81363 26.5133 10.4936 24.8333 10.4936 22.7533C10.4936 20.6733 8.8003 18.9933 6.73363 18.9933Z" fill="white"/>
                    <path d="M25.2668 18.9933C23.2002 18.9933 21.5068 20.6733 21.5068 22.7533C21.5068 24.8333 23.1868 26.5133 25.2668 26.5133C27.3468 26.5133 29.0268 24.8333 29.0268 22.7533C29.0268 20.6733 27.3468 18.9933 25.2668 18.9933Z" fill="white"/>
                  </svg>
                  <div onClick={shareHandel}>
                    اشتراک گذاری
                  </div>
                </div>
              </div>
          </div>
        </div>
        <a href="https://dailypulse.ir?utm_source=podkadeh&utm_medium=direct&utm_campaign=wellcome" className="banner" target="_blank">
          <img src={(window.innerWidth < 850) ? bannerMob : banner} alt="daily pulse"/>
        </a>
        {
          (showWarning) ? (
            <div className="episode-warning">
              <div className="row">
                <div className="icon">
                  <IconDanger/>
                </div>
                <div className="data">
                  این پادکست بر روی میزبانی انکر قرار دارد و ایران از سوی انکر تحریم شده. برای پخش اپیزود ها از ابزار های تحریم شکن استفاده کنید.
                </div>
              </div>
            </div>
          ) : ""
        }
        <div className="episode-cart">
          <div className="player">
            <div className="audio-container">
            <audio src="" ref={audioRef} id='audio-player'/>;
            </div>
            <div className="image">
              <img src={(ep.imagecache) ? ep.imagecache.size500 : ""} alt={ep.Title} onLoad={(e) => e.target.style.opacity = 1}/>
            </div>
            <div className="title">
              {ep.Title}
            </div>
            <Link to={"/podcast/" + params.EnName} className="podcast">
              {p.title}
            </Link>
            <div className="timeline">
                <SliderNew time={duration} timeElapsed={currentTime} changeFunc={timeChange} makeTimes={makeTimes}/>
            </div>
            <div className="actions">
              {
                (loadingAudio) ? (
                  <Loading/>
                ) : (
                  <>
                  <div className="icons forward" onClick={() => seekForward(15)}><img src={Forward} alt={'seek forward'}/></div>
                  <div className="icons play">
                      {(playing)
                        ? <img className="play" src={Pause} alt="play-btn" onClick={pauseFunc}/>
                        : <img className="play" src={Play} alt="play-btn" onClick={playFunc}/>
                      }
                  </div>
                  <div className="icons backwards" onClick={() => seekBackward(5)}><img src={Backward} alt={'seek backwards'}/></div>
                  </>
                )
              }
            </div>
          </div>
          <div className="line"></div>
          <div className="data">
            <div className="desc-title">
              توضیحات:
            </div>
            <div className="desc">
              {parse(ep.Description ? ep.Description : "")}
            </div>
          </div>
          
        </div>
        <div className="episodes">
          <h2 className="title-list">
            لیست اپیزود های پادکست {p.title}
          </h2>
              {getEpisodes()}
              <EndObserver func={loadMore}/>
        </div>
      </Style>
    </MainLayout>
  );
}
